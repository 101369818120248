export const PUBLIC_BUCKET_URL = 'https://bidops-public.s3.amazonaws.com';
export const ASSETS_URL = `${PUBLIC_BUCKET_URL}/assets`;
export const IMAGES_URL = `${ASSETS_URL}/images`;
export const LOGOS_URL = `${ASSETS_URL}/logos`;

export const FULL_LOGO_BLACK_URL = `${LOGOS_URL}/logo@2x.png`;
export const FULL_LOGO_WHITE_URL = `${LOGOS_URL}/logo_white@2x.png`;

export const MARKETING_SITE_URL = 'https://arkestro.com';
export const USER_AVATAR_URL = `${IMAGES_URL}/user_avatar.svg`;

export const PRIVACY_POLICY_URL = 'https://arkestro.com/privacy-policy';
export const TERMS_URL = 'https://arkestro.com/terms-of-use';

export const AVAILABLE_FILE_EXT_ICONS = ['doc', 'xlsx', 'csv', 'txt', 'pdf'];

export const REQUEST_SORTING_DATE_FIELDS = [
  'bidding_events.start_at',
  'bidding_events.end_at',
  'bid_requests.start_time',
  'bid_requests.end_time',
  'bid_requests.open_for_viewing_at'
];
