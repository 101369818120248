import { OVERVIEW_KEY } from '@components/bid_request/SupplierSurvey';

export type ID = string | number;

const PROTOCOL = process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? 'http' : 'https';
const BASE_CLIENT_URL = `${PROTOCOL}://${process.env.NEXT_PUBLIC_CLIENT_DOMAIN}`;
const BASE_PLATFORM_URL = `${PROTOCOL}://${process.env.NEXT_PUBLIC_PLATFORM_DOMAIN}`;

const REFRESH_PATH = '/api/v1/auth/refresh';
const SIGN_IN_PATH = '/api/v1/auth';
const SSO_AUTH_PATH = '/api/v1/auth/sso';
const REFRESH_IGNORE_PATHS = [
  SIGN_IN_PATH,
  REFRESH_PATH,
  SSO_AUTH_PATH
];

const APP_ROUTES = {
  LOGIN_PAGE: '/login',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password',
  CREATE_ACCOUNT: '/create_account',
  PUBLIC_REQUESTS: '/public_requests',
  SSO_LOGIN: '/sso_login',
  DEEPLINK: '/deeplink/:token',
  RECREATE_DEEPLINK: '/deeplink/:token/recreate',
  NEW_REQUEST: '/new_bid_request',
  PERSONAL_ACCESS_TOKENS: 'personal_access_tokens',
  PLAYGROUND: '/playground',
  SUPPLIER_REGISTRATION: '/supplier_registration',
  SCORE_SURVEY: '/surveys/:survey_id/score',
  SSO_LOGIN_PAGE: '/sso_login',
  SSO_AUTH_PATH: '/api/v1/auth/sso',
  SSO_AUTH_CALLBACK_PATH: '/sso/auth/callback',
  SSO_AUTH_FAILURE_PATH: '/sso/auth/failure',
  SIDEKIQ_PATH: '/sidekiq',
  API_DOCS_PATH: '/api-docs'
};

const PUBLIC_PAGE_ROUTES = [
  APP_ROUTES.PLAYGROUND,
  APP_ROUTES.SUPPLIER_REGISTRATION,
  APP_ROUTES.SCORE_SURVEY,
  APP_ROUTES.PUBLIC_REQUESTS
];

const ROOT_AUTHENTICATED_PATH = '/bid_requests';
const SUPER_ROOT_PATH = '/platform_administration/users';

class Routes {
  static get PLATFORM_DOMAIN () { return process.env.NEXT_PUBLIC_PLATFORM_DOMAIN; }

  static get PROTOCOL () { return PROTOCOL; }

  static get BASE_CLIENT_URL () { return BASE_CLIENT_URL; }

  static get BASE_PLATFORM_URL () { return BASE_PLATFORM_URL; }

  static get SUPER_ROOT_PATH () { return SUPER_ROOT_PATH; };

  static get PUBLIC_PAGE_ROUTES () { return PUBLIC_PAGE_ROUTES; };

  static get ROOT_AUTHENTICATED_PATH () { return ROOT_AUTHENTICATED_PATH; }

  static get REFRESH_IGNORE_PATHS () { return REFRESH_IGNORE_PATHS; }

  static get REFRESH_PATH () { return REFRESH_PATH; }

  static get SIGN_IN_PATH () { return SIGN_IN_PATH; }

  static get SSO_AUTH_PATH () { return SSO_AUTH_PATH; }

  static get APP_ROUTES () { return APP_ROUTES; }

  static get ONBOARDING () {
    return {
      EXPORT: {
        CATEGORIES: {
          URL: `${BASE_PLATFORM_URL}/api/v1/corporate/onboarding/export_categories`,
          PATH: 'api/v1/corporate/onboarding/export_categories'
        },
        CATALOG: {
          URL: `${BASE_PLATFORM_URL}/api/v1/corporate/onboarding/export_catalog`,
          PATH: 'api/v1/corporate/onboarding/export_catalog'
        },
        TRANSACTIONS: {
          URL: `${BASE_PLATFORM_URL}/api/v1/corporate/onboarding/export_transactions`,
          PATH: 'api/v1/corporate/onboarding/export_transactions'
        }
      }
    };
  }

  static get SIDEKIQ_URL () {
    return `${BASE_PLATFORM_URL}/sidekiq`;
  }

  static get API_DOCS_URL () {
    return `${BASE_PLATFORM_URL}/api/v1/api-docs`;
  }

  static AUTH_SSO_URL (email: string) {
    return `${BASE_PLATFORM_URL}/api/v1/auth/sso?email=${email}`;
  }

  static get MESSAGES_PATH () {
    return '/messages';
  }

  static REQUEST_MESSAGES_PATH (requestId: ID){
    return `/messages/${requestId}`;
  }

  static get GENERAL_MESSAGES_PATH (){
    return '/messages/general';
  }

  static get RECOMMENDATIONS_PATH () {
    return '/recommendations';
  }

  static RECOMMENDATION_PATH (id: ID) {
    return `${Routes.RECOMMENDATIONS_PATH}/${id}`;
  }

  static REQUEST_DASHBOARD_PATH (requestId: ID) {
    return `/bid_requests/${requestId}/dashboard`;
  }

  static REQUEST_EVENT_SCHEDULE_PATH (requestId: ID) {
    return `/bid_requests/${requestId}/schedule`;
  }

  static REQUEST_SURVEY_PATH (requestId: ID, activePage?: string) {
    if (activePage?.length && activePage !== OVERVIEW_KEY) {
      return `/bid_requests/${requestId}/survey?activePage=${activePage}`;
    }
    return `/bid_requests/${requestId}/survey`;
  }

  // TLC: Once PEPPERMINT-666 is ready for GA we should update the legacy /format
  // path to /line_items throughout the application.
  static REQUEST_LINE_ITEMS_PATH (requestId: ID, activePage?: string) {
    if (activePage?.length) {
      return `/bid_requests/${requestId}/format?activePage=${activePage}`;
    }
    return `/bid_requests/${requestId}/format`;
  }

  static REQUEST_EVENT_LINE_ITEMS_PATH (requestId: ID, activePage?: string) {
    if (activePage?.length) {
      return `/bid_requests/${requestId}/line_items?activePage=${activePage}`;
    }
    return `/bid_requests/${requestId}/line_items`;
  }

  static REQUEST_MANAGE_SUPPLIERS_PATH (requestId: ID) {
    return `/bid_requests/${requestId}/suppliers`;
  }

  static REQUEST_AWARD_SCENARIOS_PATH (requestId: ID) {
    return `/bid_requests/${requestId}/award`;
  }

  static REQUEST_AWARD_SCENARIO_EXPORT_PATH (requestId: ID, scenarioId: ID, fileFormat: string) {
    return `/bid_requests/${requestId}/award_scenarios/export?id=${scenarioId}&file_format=${fileFormat}`;
  }

  static REQUEST_AWARD_SCENARIO_EXPORT_WORKSHEET_PATH (requestId: ID, scenarioId: ID, fileFormat: string) {
    return `/bid_requests/${requestId}/award_scenarios/export?id=${scenarioId}&worksheet=true&file_format=${fileFormat}`;
  }

  static REQUEST_AWARD_SCENARIO__PATH (requestId: ID, scenarioId: ID) {
    return `/bid_requests/${requestId}/award/scenarios/${scenarioId}`;
  }

  static REQUEST_AWARD_SCENARIO_FINALIZE_PATH (requestId: ID, scenarioId: ID) {
    return `/bid_requests/${requestId}/award/scenarios/${scenarioId}?finalizeAward=true`;
  }

  static REQUEST_AWARD_SCENARIO_CRITERIA_PATH (requestId: ID, scenarioId: ID) {
    return `/bid_requests/${requestId}/award/scenarios/${scenarioId}/criteria`;
  }

  static QUOTE_SUBMISSION_PATH (requestId: ID) {
    return `/bid_requests/${requestId}/bid_submission`;
  }

  static REQUEST_SUBMISSIONS_PATH (requestId: ID) {
    return `/bid_requests/${requestId}/submissions`;
  }

  static REQUEST_SUPPLIER_QUOTE_SUBMISSION_PATH (
    requestId: ID,
    bidResponseId: ID
  ) {
    return `/bid_requests/${requestId}/submissions/quotes/${bidResponseId}`;
  }
}
export default Routes;
